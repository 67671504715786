import React, { useState } from "react"
import { Input, Radio, Table, Badge, Button, Modal } from "antd"
import Lottie from 'react-lottie-player'
import Empty from '@/components/Empty'
import { format } from '@/utils'
import TheoKeys from '@@/resources/lottie/theo-keys.json'
import * as style from "./style.module.scss"

const orders = [
  {
    id: 1,
    type: 'swap',
    status: 'pending',
    inputs: [
      {
        value: 12125,
        token: 'ADA',
        img: 'ada.png',
      }
    ],
    received: [
      {
        value: 151126.12,
        token: 'XRAY',
        img: 'ray.png',
      }
    ],
    price: 0.080231,
  },
  {
    id: 2,
    type: 'withdraw',
    status: 'completed',
    inputs: [{
      value: 66528009,
      token: 'LP',
      img: 'lp.png',
    }],
    received: [
      {
        value: 27151.19,
        token: 'ADA',
        img: 'ada.png',
      },
      {
        value: 151126,
        token: 'XRAY',
        img: 'ray.png'
      }
      
    ],
    price: 0,
  },
  {
    id: 3,
    type: 'deposit',
    status: 'failed',
    inputs: [
      {
        value: 27151.1993,
        token: 'ADA',
        img: 'ada.png',
      },
      {
        value: 151126.126,
        token: 'LQ',
        img: 'liqwid.png',
      }
    ],
    received: [
      {
        value: 145222037,
        token: 'LP',
        img: 'lp.png',
      }
    ],
    price: 0,
  },
]

const Orders = () => {
  const [tokenFilter, setTokenFilter] = useState('')
  const [orderModal, setOrderModal] = useState(false)
  const [filter, setFilter] = useState('all')

  const filteredPools = orders
    .filter((token) => filter === 'all' ? true : token.status === filter)
    .filter((token) => {
      return token.txid?.toLowerCase().includes(tokenFilter)
        || token.inputs.some((input) => input.token.toLowerCase().includes(tokenFilter))
        || token.received.some((input) => input.token.toLowerCase().includes(tokenFilter))
    })


  const columns = [
    {
      width: '350px',
      title: "Order",
      dataIndex: "inputs",
      key: "inputs",
      render: (record, records) => {
        return (
          <div
            className={style.swap}
            onClick={() => setOrderModal(true)}
            onKeyPress={() => setOrderModal(true)}
          >
            <div className={style.swapIcon}>
              <i className="ri ri-settings" />
            </div>
            <div className={style.inputs}>
              <div className={style.tokenContainer}>
                {record.map((item) => {
                  return (
                    <div className={style.token}>
                      <img src={`/resources/tokens/${item.img}`} alt="" />
                      <div className="lh-1">
                        <div><strong>{item.token}</strong></div>
                        <div className="font-size-12 text-muted">{format(item.value)}</div>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
            <div className={style.arrow}>
              <i className="ri ri-arrow_forward" />
            </div>
            <div className={style.outputs}>
              <div className={style.tokenContainer}>
                {records.received.map((item) => {
                  return (
                    <div className={style.token}>
                      <img src={`/resources/tokens/${item.img}`} alt="" />
                      <div className="lh-1">
                        <div><strong>{item.token}</strong></div>
                        <div className="font-size-12 text-muted">{format(item.value)}</div>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        )
      }
    },
    {
      responsive: ["md"],
      title: "Rate",
      dataIndex: "price",
      key: "price",
      render: (record) => {
        return (
          <div>
            {record > 0 && <strong>{record} ADA</strong>}
            {record <= 0 && '—'}
          </div>
        )
      }
    },
    {
      responsive: ["md"],
      title: "Type",
      dataIndex: "type",
      key: "type",
      sorter: (a, b) => a.status.localeCompare(b.status),
      render: (record) => <strong className="badge badge-token">{record}</strong>
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      sorter: (a, b) => a.status.localeCompare(b.status),
      render: (record) => {
        return (
          <div className="font-size-14">
            {record === 'pending' && <Badge status="processing" text="Pending" />}
            {record === 'completed' && <Badge status="success" text="Completed" />}
            {record === 'failed' && <Badge status="error" text="Failed" />}
          </div>
        )
      }
    },
    // {
    //   responsive: ["sm"],
    //   width: '40px',
    //   title: "",
    //   dataIndex: "actions",
    //   key: "actions",
    //   render: (record, records) => {
    //     return (
    //       <Button
    //         // disabled={records.status === 'pending'}
    //         className="ray__btn ray__btn ray__btn--transparent ray__btn--dark px-2"
    //         onClick={() => setOrderModal(true)}
    //       >
    //         <i className="ri ri-settings" />
    //       </Button>
    //     )
    //   }
    // },
  ]

  return (
    <div className="ray__block"> 
      <Modal
        title={null}
        footer={null}
        visible={orderModal}
        onCancel={() => setOrderModal(false)}
        width={540}
        closeIcon={<span className="ri ri-close" />}
        zIndex={1001}
      >
        <div className="ray__heading mb-3">
          Order Info
        </div>
        <div className="text-center pt-4">
          <h5 className="mb-1">
            <strong>Order Info</strong>
          </h5>
          <div className="text-muted">Detailed order information will be available soon</div>
        </div>
        <div className={style.lottie}>
          <Lottie animationData={TheoKeys} play />
        </div>
        <Button
          className="ant-btn ray__btn ray__btn--large ray__btn--transparent w-100"
          onClick={() => setOrderModal(false)}
        >
          <i className="ri ri-close me-2" />
          Close
        </Button>
      </Modal>
      <div className={style.container}>
        <h5 className="d-flex w-100">
          <strong>Orders History</strong>
        </h5>
        <div className="d-flex mb-2">
          <div className="pt-1">
            <Radio.Group
              onChange={(e) => setFilter(e.target.value)}
              value={filter}
            >
              <Radio value="all">All</Radio>
              <Radio value="pending">Pending</Radio>
              <Radio value="failed">Failed</Radio>
            </Radio.Group>
          </div>
          <div className="ms-auto">
            <Input
              size="default"
              placeholder="Search order..."
              allowClear
              onChange={(e) => setTokenFilter(e.target.value.toLowerCase())}
            />
          </div>
        </div>
        {!!filteredPools.length && (
          <div className={`ray__table ${style.poolsContainer}`}>
            <Table
              showSorterTooltip={false}
              rowKey="id"
              dataSource={filteredPools}
              columns={columns}
              pagination={false}
            />
          </div>
        )}
        {!filteredPools.length && <Empty title="No Orders Found" message="Try another token name or tx id" />}
      </div>
    </div>
  )
}

export default Orders
