// extracted by mini-css-extract-plugin
export var arrow = "style-module--arrow--SDXoh";
export var container = "style-module--container--Rv+GN";
export var create = "style-module--create--iL01x";
export var inputs = "style-module--inputs--U1U+A";
export var lottie = "style-module--lottie--f+iTa";
export var outputs = "style-module--outputs--Me+8H";
export var poolsContainer = "style-module--poolsContainer--0LIx+";
export var swap = "style-module--swap--hTaIc";
export var swapIcon = "style-module--swapIcon--0nD2g";
export var token = "style-module--token--Oec0c";
export var tokenContainer = "style-module--tokenContainer--sJUib";